<script lang="ts" setup>
import { debounce } from "perfect-debounce";

const { t } = useI18n();

const store = await useStore();
const { term } = await useCatalog(store.value, null);

const searchTerm = ref(term?.value ?? "");

function updateSearchTerm() {
  if (searchTerm.value.length > 0 && searchTerm.value.length < 3) {
    return;
  }

  term.value = searchTerm.value !== "" ? searchTerm.value : undefined;
}

const debouncedFetchData = debounce(updateSearchTerm, 200);

watch(() => searchTerm.value, () => {
  debouncedFetchData();
});

function onFocus() {
  updateSearchTerm();
}
</script>

<template>
  <form @submit.prevent="updateSearchTerm">
    <UInput
      v-model="searchTerm"
      icon="i-ph-magnifying-glass-thin"
      :placeholder="t('catalog-filters.placeholder.search-by-name')"
      :ui="{
        base: '!ring-0 !bg-transparent !shadow-none !pl-[34px]',
        icon: { leading: { padding: { xl: '!pl-0' } } },
      }"
      class="border-b-gradient-black"
      @focus="onFocus"
    />
  </form>
</template>

<style scoped>

</style>
