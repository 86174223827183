<script lang="ts" setup>
import type { FilterGroupMultiSelect } from "~~/models/filters";

const { filterGroup } = defineProps<{ filterGroup: FilterGroupMultiSelect }>();

defineEmits(["update"]);

const { t } = useI18n();

function getSelectedListLabels(filterGroup: FilterGroupMultiSelect): string {
  const labels = filterGroup.selectedList?.map(s => filterGroup.options.find(o => o.value === s)?.label);

  if (!labels?.length) {
    return t("catalog-filters.placeholder.selected-value");
  }

  return labels.join(", ");
}
</script>

<template>
  <UFormGroup :label="filterGroup.title" :class="{ hidden: filterGroup.isHiddenInUI }">
    <USelectMenu
      v-model="(filterGroup as FilterGroupMultiSelect).selectedList!"
      :options="filterGroup.options"
      :popper="{ offsetDistance: 2 }"
      selected-icon=""
      option-attribute="label"
      value-attribute="value"
      multiple
      data-1p-ignore
      @change="$emit('update')"
    >
      <template #label>
        <span class="truncate">{{ getSelectedListLabels(filterGroup) }}</span>
      </template>
      <template #option="{ option, selected }">
        <div class="flex items-center gap-2.5">
          <div class="size-[24px] rounded-md border-2 border-gray-400 text-center">
            <UIcon v-if="selected" name="i-ph-check-bold" class="text-primary-300" />
          </div>
          <div>{{ option.label }}</div>
        </div>
      </template>
    </USelectMenu>
  </UFormGroup>
</template>

<style scoped>

</style>
