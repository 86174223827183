<script lang="ts" setup>
import type { ProductDto } from "~~/models/products";

const { product } = defineProps<{ product: ProductDto }>();

const { t } = useI18n();
const localePath = useLocalePath();

const productImage = computed(() => product.images.at(0));

const { canSelectMoreProducts, selectProduct, deselectProduct, isProductSelected } = useProductComparisonManager();

const selectedForComparison = computed<boolean>({
  get: () => {
    return isProductSelected(product);
  },
  set: async (newValue: boolean) => {
    if (newValue) {
      selectProduct(product);
    }
    else {
      deselectProduct(product);
    }
  },
});
</script>

<template>
  <div class="flex flex-col">
    <NuxtLinkLocale :to="localePath(`/product/${product.slug}--${product.id}`)" class="mb-5 cursor-pointer !no-underline">
      <div class="flex aspect-[1/1] w-full flex-col items-end bg-black/10 p-[10px]">
        <UBadge v-if="product.machineType?.value?.length" class="w-fit rounded bg-white text-sm text-black ">
          {{ product.machineType.value }}
        </UBadge>

        <img
          v-if="productImage"
          :src="productImage.url"
          :alt="productImage.name ?? ''"
          class="w-full flex-1 object-cover mix-blend-multiply"
          loading="lazy"
        >

        <div v-else class="size-full" />
      </div>
    </NuxtLinkLocale>

    <NuxtLinkLocale :to="localePath(`/product/${product.slug}--${product.id}`)" class="mb-md">
      <h3 class="leading-p-150 mb-0 text-lg font-bold text-black">
        {{ product.name }}
      </h3>
    </NuxtLinkLocale>

    <div class="gap-xs mb-8 flex w-full justify-between">
      <UBadge v-for="cardProperty of product.machineTypeCardProperties" :key="cardProperty.key" class="bg-primary-100 rounded text-sm text-black">
        {{ cardProperty.label }}: {{ cardProperty.value }}
      </UBadge>
    </div>

    <div class="mt-auto">
      <UCheckbox
        v-model="selectedForComparison"
        :disabled="!canSelectMoreProducts && !isProductSelected(product)"
        :name="`compare-product-${product.id}`"
        :label="t('catalog.action.compare-model')"
        class="mb-md"
      />

      <UButton :to="localePath(`/product/${product.slug}--${product.id}`)" color="black" size="sm" class="w-fit">
        {{ $t("catalog.action.view-model") }}
      </UButton>
    </div>
  </div>
</template>

<style scoped>

</style>
