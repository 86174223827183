<script setup lang="ts">
const store = await useStore();
const { pagination, page } = await useCatalog(store.value, null);
</script>

<template>
  <UPagination
    v-if="pagination.totalItems > 0"
    v-model="page"
    :page-count="pagination.pageSize"
    :total="pagination.totalItems"
    :max="5"
    :active-button="{ color: 'black' }"
  >
    <template #prev="{ onClick }">
      <UButton color="black" variant="outline" size="sm" icon="i-ph-arrow-left" @click="onClick">
        {{ $t("pagination.action.previous") }}
      </UButton>
    </template>

    <template #next="{ onClick }">
      <UButton color="black" variant="outline" size="sm" icon="i-ph-arrow-right" trailing @click="onClick">
        {{ $t("pagination.action.next") }}
      </UButton>
    </template>
  </UPagination>
</template>
