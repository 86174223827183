<script lang="ts" setup>
import type { BreadcrumbLink } from "#ui/types";

const { breadcrumbs, colorMode } = defineProps<{ breadcrumbs?: Array<BreadcrumbLink>; colorMode: "white" | "primary" }>();

const { t } = useI18n();
const localePath = useLocalePath();

const breadcrumbList = [] as Array<BreadcrumbLink>;
breadcrumbList.push({ label: t("page.title.home"), to: localePath("/") });

if (breadcrumbs) {
  for (const breadcrumb of breadcrumbs) {
    breadcrumbList.push(breadcrumb);
  }
}

const colorConfig = {
  li: colorMode === "white" ? "text-white hover:text-white" : "text-primary hover:text-primary",
  divider: { base: colorMode === "white" ? "text-white" : "text-primary" },
  inactive: colorMode === "white" ? "hover:text-white" : "hover:text-primary",
  active: colorMode === "white" ? "text-white" : "text-primary",
};
</script>

<template>
  <UBreadcrumb
    v-if="breadcrumbList.length > 1" :links="breadcrumbList" :ui="colorConfig"
  />
</template>

<style scoped>

</style>
