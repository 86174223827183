<script lang="ts" setup>
import type { FilterGroupMultiSelect } from "~~/models/filters";

const store = await useStore();
const { propertyFilters, updatePropertyFilters } = await useCatalog(store.value, null);
</script>

<template>
  <h2 class="text-xl font-bold text-black">
    {{ $t("catalog-filters.title") }}
  </h2>

  <div class="gap-2xl flex flex-col">
    <ProductSearchTerm />

    <ProductSearchFilterMultiSelect
      v-for="filterGroup of propertyFilters" :key="filterGroup.title"
      :filter-group="filterGroup as FilterGroupMultiSelect"
      @update="updatePropertyFilters"
    />
  </div>
</template>

<style scoped>

</style>
