<script lang="ts" setup>
const { cmsFilter } = defineProps<{ cmsFilter?: any }>();

const { t } = useI18n();

const store = await useStore();
const { products, pagination, pending, error } = await useCatalog(store.value, cmsFilter, { immediate: true });

const isOpenMobileFilter = ref(false);
const isMounted = ref(false);

onMounted(() => isMounted.value = true);
</script>

<template>
  <LazyProductCompareBannerBase />

  <UContainer id="product-search-base" class="my-4xl">
    <FeedbackMessage v-if="error" :message="error.message" message-type="error" class="my-2" />

    <div v-else-if="pending">
      <Loading />
    </div>

    <div v-else-if="isMounted">
      <div class="gap-3xl flex">
        <div class="hidden w-[275px] lg:block">
          <ProductSearchPropertyFilters />
        </div>

        <div v-if="pagination.totalItems > 0" class="flex-1">
          <div class="mb-xl flex justify-between">
            <div class="flex items-end">
              {{ pagination.totalItems }} {{ $t("catalog-filters.label.results") }}
            </div>

            <UButton color="primary" trailing-icon="ph-sliders-horizontal" class="text-md lg:hidden" @click="isOpenMobileFilter = true">
              {{ $t("catalog-filters.action.filters") }}
            </UButton>
          </div>

          <div class="mb-xl md:mb-3xl grid grid-cols-1 gap-x-7 gap-y-14 sm:grid-cols-2 md:grid-cols-3">
            <ProductCard v-for="product of products" :key="product.id" :product="product" />
          </div>

          <ProductSearchPagination class="ml-auto w-fit" />
        </div>
      </div>

      <FeedbackMessage
        v-if="!error && !pending && pagination.totalItems === 0"
        :message="t('catalog.message.warning.no-products')" message-type="warning" class="my-2 w-full"
      />
    </div>
  </UContainer>

  <LazyUSlideover v-model="isOpenMobileFilter" side="left" prevent-close>
    <div class="flex flex-1 overflow-y-auto">
      <div class="size-full">
        <div class="p-3">
          <div class="flex items-center justify-end">
            <label class="sr-only" for="close-mobile-filter">{{ $t('common.action.close') }}</label>
            <UIcon id="close-mobile-filter" name="i-ph-x" class="size-lg" role="button" @click="isOpenMobileFilter = false" />
          </div>

          <ProductSearchPropertyFilters />
        </div>

        <div class="sticky bottom-0 border-t bg-white p-3">
          <UButton variant="solid" color="primary" size="lg" block @click="isOpenMobileFilter = false">
            {{ $t("catalog-filters.action.show-products", { Count: pagination.totalItems }) }}
          </UButton>
        </div>
      </div>
    </div>
  </LazyUSlideover>

  <!--  -->
</template>

<style scoped>

</style>
