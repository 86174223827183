<script lang="ts" setup>
import type { BreadcrumbLink } from "#ui/types";
import type { ButtonRecord, ImageFileField } from "~~/server/graphql/datocms/generated";

const props = withDefaults(defineProps<{
  breadcrumbs?: Array<BreadcrumbLink>;
  title: string;
  optIntro?: string;
  optImage?: ImageFileField;
  ctaList?: Array<ButtonRecord>;
  colorMode?: boolean;
}>(), {
  ctaList: () => [],
  colorMode: false,
});

const localePath = useLocalePath();

const ctaList = computed(() => {
  return props.ctaList?.map((c) => {
    const link = getLinkForType(c.linkItem, localePath);

    return {
      id: c.id,
      label: link.label,
      url: link.href ?? localePath("/"),
      primary: c.primary,
    };
  }) ?? [];
});
</script>

<template>
  <div :class="$attrs.class">
    <div class="page-header-container">
      <div
        v-if="optImage"
        class="page-header-container__image"
      >
        <img :src="optImage?.responsiveImage.src" :alt="optImage?.alt ?? ''" :title="optImage?.title ?? ''" class="h-[718px] w-full object-cover">
      </div>

      <div
        v-if="optImage"
        class="page-header-container__overlay overlay z-[1]"
        :class="{ 'overlay--primary': colorMode, 'overlay--neutral': !colorMode }"
      />

      <div
        class="page-header-container__content z-[2] size-full"
        :class="{ 'flex flex-col': optImage }"
      >
        <div
          :class="{
            'bg-primary': !optImage && colorMode,
            'bg-neutral': !optImage && !colorMode,
            'mt-auto': optImage,
          }"
        >
          <UContainer class="py-xl sm:py-4xl inset-x-0">
            <div class="text-white">
              <Breadcrumbs :breadcrumbs="breadcrumbs" color-mode="white" class="mb-xl" />
              <h1 class="mb-0 text-3xl uppercase text-inherit sm:text-5xl" v-html="title" />
              <div v-if="optIntro" class="mt-xl text-balance [&>*]:text-inherit" v-html="optIntro" />

              <div class="gap-sm flex flex-col sm:flex-row">
                <slot name="custom-call-to-actions" />

                <div v-if="ctaList.length" class="mt-xl gap-sm flex flex-col sm:flex-row">
                  <div v-for="cta of ctaList" :key="cta.id">
                    <UButton v-if="cta.primary" :to="cta.url" color="white" icon="i-ph-arrow-right" trailing>
                      {{ cta.label }}
                    </UButton>

                    <UButton v-if="!cta.primary" :to="cta.url" color="white" variant="outline" icon="i-ph-arrow-right" trailing>
                      {{ cta.label }}
                    </UButton>
                  </div>
                </div>
              </div>
            </div>
          </UContainer>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.page-header-container {
  display: grid;
  grid-template-areas: "stack";

  .page-header-container__image {
    grid-area: stack;
  }

  .page-header-container__overlay {
    grid-area: stack;
  }

  .page-header-container__content {
    grid-area: stack;
  }
}

.overlay::before {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
}

.overlay--primary::before {
  background: linear-gradient(0deg, rgb(var(--color-primary) / 0.96) 0%, rgb(var(--color-primary) / 0) 100%);
}

.overlay--neutral::before {
  background: linear-gradient(0deg, rgb(var(--color-black) / 1) 0%, rgb(var(--color-black) / 0) 100%);
}
</style>
